/* eslint-disable jsx-a11y/media-has-caption */
import { useRef, useState } from 'react';
import { useSetState, useVideo, useFullscreen } from 'react-use';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { isMobile, isIOS } from 'react-device-detect';
import cn from 'classnames';
// Helpers
import { computeDuration } from 'helpers/time';
// Ui
import FourDotsLoader from 'ui/FourDotsLoader/FourDotsLoader';
// Components
import VideoSeek from 'ui/VideoPlayer/components/VideoSeek/VideoSeek';
import MicControl from 'ui/VideoPlayer/components/MicControl/MicControl';
import VolumeSeek from 'ui/VideoPlayer/components/VolumeSeek/VolumeSeek';
import CameraOption from 'ui/VideoPlayer/components/CameraOption/CameraOption';
import VideoSettings from 'ui/VideoPlayer/components/VideoSettings/VideoSettings';
import QualityOptions from 'ui/VideoPlayer/components/QualityOptions/QualityOptions';
import VideoControlButton from 'ui/VideoPlayer/components/VideoControlButton/VideoControlButton';
// Styles
import styles from './VideoPlayer.module.scss';

type LocalAudio = {
  muted: boolean;
  volume: number;
};

type VideoAttributes = Omit<
  React.VideoHTMLAttributes<any>,
  'controls' | 'placeholder'
>;

type QualityProps =
  | {
      qualityOptions: { label: string; value: number }[];
      onQualityChange: (quality: number) => void;
    }
  | {
      qualityOptions?: never;
      onQualityChange?: never;
    };

type VolumeProps =
  | {
      onVolume?: (volume: number) => void;
      onMute: () => void;
      onUnMute: () => void;
    }
  | {
      onVolume?: never;
      onMute?: never;
      onUnMute?: never;
    };

export type VideoPlayerProps = {
  onPlay?: () => void;
  onPause?: () => void;
  onCameraToggle?: () => void;
  onPlayNext?: () => void;
  withoutAspectRatio?: boolean;
  debug?: boolean;
  pausePoster?: string;
  wrapperClassName?: string;
  volumeControl: 'mic' | 'speaker';
  labels?: JSX.Element | null;
  settings?: JSX.Element;
  placeholder?: JSX.Element;
  videoClassName?: string;
} & QualityProps &
  VolumeProps &
  VideoAttributes;

const VideoPlayer = ({
  onPlay,
  onPause,
  onVolume,
  onMute,
  onUnMute,
  onPlayNext,
  qualityOptions,
  onQualityChange,
  onCameraToggle,
  src,
  withoutAspectRatio,
  debug,
  pausePoster,
  wrapperClassName,
  volumeControl = 'speaker',
  labels,
  settings,
  placeholder,
  videoClassName,
  ...rest
}: VideoPlayerProps) => {
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [localAudio, setLocalAudio] = useSetState<LocalAudio>({
    muted: false,
    volume: 1,
  });

  const [video, state, controls] = useVideo(
    <video
      className={videoClassName ? videoClassName : styles.video}
      src={src || ''}
      disablePictureInPicture
      {...rest}
      controls={false}
      data-testid="video-player"
      onEnded={onPlayNext}
    />
  );

  const rootRef = useRef<HTMLDivElement | null>(null);
  const isMobileIOS = isMobile && isIOS;

  // show fullscreen option for all cases but not for mobile IOS and allow a fullscreen
  // fallback for mobile IOS only if it's a stream (assuming that there is no 'src' value)
  const isFullscreenOptionAvailable = !isMobileIOS || (isMobileIOS && !src);
  const isUseFullscreenEnable = fullscreen && !isMobileIOS;
  const isFullscreenFallbackEnable = fullscreen && isMobileIOS;

  useFullscreen(rootRef, isUseFullscreenEnable, {
    onClose: () => setFullscreen(false),
  });

  const { duration, muted, time, volume, paused, playing, buffered } = state;

  const isBuffering = Boolean(!paused && !playing && src);
  const isQualityOptionsVisible = Boolean(qualityOptions && onQualityChange);
  // use local value if 'onVolume' and related props exists.
  // necessary to prevent any video volume change. use case: changing microphone volume
  const volumeValue = onVolume ? localAudio.volume : volume;
  const mutedValue = onVolume ? localAudio.muted : muted;
  const isMicVolumeControl = volumeControl === 'mic';
  // because mic is only for streamer view we can use this

  const handlePlayToggle = () => {
    if (playing) {
      controls.pause();

      if (onPause) {
        onPause();
      }
    } else {
      controls.play();

      if (onPlay) {
        onPlay();
      }
    }
  };

  const handleVideoSeekChange = (time: number) => {
    controls.seek(time);
  };

  const handleVolumeSeekChange = (volume: number) => {
    if (onVolume) {
      onVolume(volume);
      setLocalAudio({ volume });
    } else {
      controls.volume(volume);
    }
  };

  const handleVolumeSeekMute = () => {
    if (onMute) {
      onMute();
      setLocalAudio({ muted: true });
    } else {
      controls.mute();
    }
  };

  const handleVolumeSeekUnmute = () => {
    if (onUnMute) {
      onUnMute();
      setLocalAudio({ muted: false });
    } else {
      controls.unmute();
    }
  };

  const handleQualityChange = (quality: number) => {
    if (onQualityChange) {
      onQualityChange(quality);
    }
  };

  const handleFullscreenToggle = () => {
    // Safari and Chrome on mobile iOS does not allow web pages been full screen
    if (isMobileIOS) {
      const body = document.body;
      // adding a z-index to the 'main' tag is necessary to override the z-index of the fixed header. without this, the fullscreen will not be complete and will be overlapped by the header
      const mainTagStyle = document.querySelector('main')?.style;
      // adding a z-index to the 'hubspot' widget is necessary to override the z-index of the 'hubspot'. without this, the widget will overlapped the fullscreen mode
      const hubspotWidgetStyle = document.getElementById(
        'hubspot-messages-iframe-container'
      )?.style;

      if (fullscreen) {
        setFullscreen(false);
        enableBodyScroll(body);

        if (mainTagStyle) {
          mainTagStyle.zIndex = '';
        }

        if (hubspotWidgetStyle) {
          hubspotWidgetStyle.zIndex = '';
        }
      } else {
        setFullscreen(true);
        disableBodyScroll(body);

        if (mainTagStyle) {
          mainTagStyle.zIndex = '999';
        }

        if (hubspotWidgetStyle) {
          hubspotWidgetStyle.zIndex = '-999';
        }
      }
    } else {
      setFullscreen((prev) => !prev);
    }
  };

  return (
    <div
      className={cn(
        styles.root,
        {
          [styles.withoutAspectRatio]: withoutAspectRatio,
          [styles.fullscreen]: isFullscreenFallbackEnable,
        },
        wrapperClassName
      )}
      ref={rootRef}
    >
      {labels}

      {placeholder && (
        <div className={styles.placeholderWrapper}>{placeholder}</div>
      )}

      {video}

      {(!!pausePoster || Boolean(paused && pausePoster)) && (
        <img
          src={pausePoster}
          alt="Pause poster"
          className={styles.coverImage}
        />
      )}

      {debug && (
        <pre className={styles.debug}>{JSON.stringify(state, null, 2)}</pre>
      )}

      {isBuffering && (
        <FourDotsLoader
          wrapperClassName={styles.loader}
          overlayBackgroundColor="transparent"
          loaderWrapperBackgroundColor="transparent"
        />
      )}

      <div className={styles.controls}>
        {!!src && (
          <VideoSeek
            buffered={buffered}
            time={time}
            duration={duration}
            onSeek={handleVideoSeekChange}
          />
        )}

        <div className={styles.controlsBottom}>
          <div className={styles.left}>
            <VideoControlButton
              icon={playing ? 'pause' : 'play'}
              title={playing ? 'Pause' : 'Play'}
              onClick={handlePlayToggle}
              className={styles.controlsButton}
            />

            {onPlayNext && (
              <VideoControlButton
                icon="play-next"
                title="Play next"
                onClick={onPlayNext}
                className={styles.controlsButton}
              />
            )}

            {isMicVolumeControl ? (
              <MicControl
                muted={mutedValue}
                onMute={handleVolumeSeekMute}
                onUnMute={handleVolumeSeekUnmute}
              />
            ) : (
              <VolumeSeek
                volume={volumeValue}
                muted={mutedValue}
                onSeek={handleVolumeSeekChange}
                onMute={handleVolumeSeekMute}
                onUnMute={handleVolumeSeekUnmute}
              />
            )}

            <time className={styles.time}>
              {computeDuration(time)} / {computeDuration(duration)}
            </time>
          </div>

          <div className={styles.right}>
            {settings && <VideoSettings settings={settings} />}

            {isQualityOptionsVisible && qualityOptions?.length && (
              <QualityOptions
                options={qualityOptions}
                onChange={handleQualityChange}
              />
            )}

            {onCameraToggle && <CameraOption onToggle={onCameraToggle} />}
            {isFullscreenOptionAvailable && (
              <VideoControlButton
                icon="fullscreen"
                title={fullscreen ? 'Exit full screen' : 'Full screen'}
                onClick={handleFullscreenToggle}
                className={styles.controlsButton}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
